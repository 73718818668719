import styled from 'styled-components';

const CardContainer = styled.div`
width: 100%;
background: white;
border-radius: 5px;

@media (max-width: 1200px){
	margin: 0 0 15px;
}
`;
const Header = styled.div`
width: 100%;  
height: 35px;
background: linear-gradient(180deg, #52A8ED 0%, #8AD3F7 100%);
border-radius: 6px 6px 0px 0px;

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 0 13px;
    > span: first-child {
      font-family: Rubik;
      font-weight: 500;
      font-size: 18px;
	  color: #0D4270;
	  line-height: 24px;

			float: left;

			@media (max-width: 302px){
				margin-top: -10px;
			}
  
    }
  
    > span: last-child {
      float: right;
      font-family: Rubik;
      font-weight: 500;
      font-size: 14px;
      color: #0D4270;
			margin-top: 5px;
			display: none;

			@media (max-width: 302px){
				margin-top: -5px;
			}
    }
  }
`;
const SliderContainer = styled.div`
.slick-slider {
  width: 100%;

  margin: 16px 0px 0px;
  .slick-slide slick-active slick-current{
	  width: auto;
	min-width: 365px;
	max-width: 399px;

  }
  .slick-slide{
	width: auto;
	min-width: 365px;
	max-width: 399px;

  }
  .slick-initialized {
	width: auto;
	min-width: 365px;
	max-width: 399px;

  }
  @media (min-width: 1246px){
    max-width: 1246px;
}
  .slick-track {
		height: 250px;
		@media (max-width: 1200px) and (min-width: 600px) {
			height: 95px;
		}
		@media (max-width: 600px){
			height: 195px;
		}
	}
	.slick-dots {
		bottom: -9px;
		li button:before {
			color: #0D4270;
		}
		li.slick-active button:before {
			color: #0D4270;
		}

		@media (max-width: 1200px){
			bottom: 10px;
		}
	}
}
`;
export { CardContainer, Header, SliderContainer };