import styled, { css } from 'styled-components';
const RegularFont = 'Rubik-Regular';

const Card = styled.div`
width: 100%;
height: 155px;
background-color: white;
border-radius: 5px;
`;
const ImageContainer = styled.div`
  width: ${({width}) => width ? width : '100%'};
  height: ${({width}) => width ? '100%' : '100%'};
  float: left;
  
  > img {
    width: 100%;
    height: 100%;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    border-radius: 6px;
  }

  > div {
    display: flex;
    justify-content: center;
  }
  ${({width}) => width && css`
    position: absolute;  
  `}
`;
const CardInfo = styled.div`
width: 60%;
float: left;
height: 100%;
border-bottom-right-radius: 5px;
border-top-right-radius: 5px;
display: flex;
flex-flow: column;
justify-content: space-between;
`;
const CardName = styled.div`
width: 100%;
height: 30px;
padding-left: 10px;
padding-top: 10px;
color: #FD7175;
font-family: Rubik;
font-weight: bold;
font-size: 17px;

`;
const DateContainer = styled.div`
font-size: 12px;
font-family: Rubik;
line-height: 24px;
font-weight: normal;
padding-left: 10px;
color: rgb(147, 149, 155);
`;
const CardTitle = styled.div`
color: #0D4270;
font-family: Rubik;
font-weight: bold;
font-size: 17px;
padding-left: 10px;
`;
const Button = styled.button`
width: 100%;
background-color: #FD7175;
border-radius: 0px 0px 6px 0px;
border: none;
height: 30px;
color: #FFF;
`;
const RecipeContainer = styled.div`
display: flex;
width: 100%;
`;
const TimeContainer = styled.span`
font-size: 11px;
font-family: Rubik;
color: #3A3A3A;
padding-left: 10px;
  > img {
    height: 15px;
    width: 15px;
    float: left;
    margin-right: 5px;
  }
`;
const Servings = styled.div`
font-size: 11px;
font-family: Rubik;
color: #3A3A3A;
padding-left: 10px;
  > img {
    height: 15px;
    width: 15px;
    float: left;
    margin-right: 5px;
  }
`;
const MealType = styled.div`
font-size: 11px;
font-family: Rubik;
color: #3A3A3A;
padding-left: 10px;
  > img {
    height: 15px;
    width: 15px;
    float: left;
    margin-right: 5px;
  }
`;
const CategoryName = styled.div`
font-size: 10px;
font-family: Rubik;
color: #93959B;
`;

const PlayIconContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

const PlayIcon = styled.p`
  width: 66px;
  height: 66px;
  background-color: #000000;
  border-radius: 50%;
  vertical-align: middle;
  position: absolute;
  top: calc(50% - 33px);
  opacity: ${({opacity}) => opacity ? '1' : '0.5'};
  cursor: pointer;
  > i {
    color: white;
    font-size: 20px;
    margin-left: 3px;
  }

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
`;
const ArticleCard = styled.div`
width: 100%;
height: 250px;
background-color: white;
border-radius: 5px;
position: relative;

  .timeToRead {
    position: absolute;
    color: #FFFFFF;
    font-size: 14px;
    bottom: 75px;
    padding: 10px 10px 0px 10px;
  }

.ArticleDetails {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 10px 0px 10px;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 6px;
  .ArticleName {
    color: #FFFFFF;
    text-transform: capitalize;
    font-weight: normal;
    font-size: 16px;
    margin: 100px auto;
    font-family: rubik-medium;
  }

}
`;
const ArticleButton = styled.button`
position: absolute;
width: 100%;
background-color: #FD7175;
border-radius: 0px 0px 6px 6px;
border: none;
height: 30px;
color: #FFF;
bottom: 0px;
`;
const MainContainer = styled.div`
width: calc((100% - 50px) / 3);
max-width: 382px;
width: 100%;
margin-right:25px;
&:last-child{margin-right:0px};
border-radius: 6px;
display: flex;
`;

const BottomCard = styled.div`
 width: 100%;
 background: white;
 align-items: center;
 font-family: Rubik;
    font-size: 16px;
    line-height: 20px;
    color: #0D4270;
    height: 60px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 20px 5px 20px 15px;
    border-radius: 0 0 6px 6px;
`;

const CardNew = styled.div`
  float: left;
  margin-left: ${({videoCSS}) => !videoCSS && '0px'};
  margin-bottom: 0px;
  padding: 0px!important;
  position: relative;
  width: ${({videoCSS}) => videoCSS ? '100%' : '100%'};
  display: flex;
  flex-wrap: wrap;
  border-radius: 6px;
  img {
    width: 100%;
    height: 240px;
    float: left;
    cursor:pointer;
    border-radius: 0px;
    &:hover, &:focus, &:active {
      background: rgba(0, 0, 0, 0.3);
border-radius: 6px;

    }
  }
.image-card{
  width: 100%;
}
  .educateImageBackground {
    position: absolute;
    top: 0;
    width: 100%;
    height: 240px;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px 6px 0 0;
    .getStarted {
      font-family: 'Rubik-Medium';
      font-size: 18px;
      line-height: 21px;
      min-width: 170px;
      height: 40px;
      background: ${({background}) => background ? background : '#FD7175'};
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-radius: 6px;
      color: white;
      text-align: center;
      padding: 0px 10px;
    }
  }
  .content {
    display: ${({recommended}) => recommended ? 'flex' : 'block'};
    align-items: center;
    padding: 0px 15px;
    float: left;
    width: 100%;
    height: 70px;
    .typeOfWork {
      font-size: 13px;
      letter-spacing: 0px;
      color: rgb(21,159,202);
      font-family: ${RegularFont};
      margin-bottom: 5px;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .workName {
      letter-spacing: 0px;
      color: #0D4270;
      margin-bottom: ${({recommended}) => recommended ? '0' : '5px'};
      text-align: left;
      overflow: hidden;
      cursor: pointer;
      display: flex;
      align-items: center;
      font-family: Rubik;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
    }
    .description {
      font-size: 13px;
      letter-spacing: 0px;
      line-height: 18px;
      color: #999999;
      font-family: ${RegularFont};
      text-align: left;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      display: -webkit-box;
      max-width: 100%;
      text-overflow: ellipsis;
    }
  }

  .video-category {
    width: 100%;
    height: 235px;

    img {
      width: auto;
      height: 100px;
    }
  }
`;

const WeekdayText = styled.div`
 width: 100%;
 background: white;
 display: flex;
 justify-content: center;

 align-items: center;
 font-family: 'Rubik-Medium';
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: ${({color}) => color ? color : '#0D4270'};
    height: 46px;
    border-radius: 6px 6px 0px 0px;
`;

const MainContainerV2 = styled.div`
width: 100%;
border-radius: 6px;
display: flex;
>div{
  width: calc((100% - 50px) / 3);
  margin-right:25px;
  &:last-child{margin-right:0px};
}
`;

export { Card, ImageContainer, CardInfo, CardName, DateContainer, CardTitle, Button,
  RecipeContainer, TimeContainer, Servings, MealType, CategoryName, PlayIcon, ArticleButton, ArticleCard,
  MainContainer, PlayIconContainer,BottomCard, CardNew, WeekdayText, MainContainerV2
};