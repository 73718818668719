import React, { Component } from 'react';
import PropTypes from "prop-types";
import { BottomCard, CardNew, WeekdayText } from './style';
import { ImageUrl } from '../../../../utils/constants';
import Thumbnail from '../../../Thumbnail';
import { MainContainerV2 } from './style';
import { withTranslation } from 'react-i18next';

class Cards extends Component {

  constructor() {
    super();
    this.state = {
      onHover: -1,
    }
  }

  onEnterMouse = (id) => {
    this.setState({
      onHover: id
    })
  };

  onLeaveMouse = () => {
    this.setState({
      onHover: -1
    })
  };

  cardClick = (type, details) => {
    const { history } = this.props;
    if (type === 'RECIPE') {
      history.push(`/education/recipes/${details['id']}`)
    } else if (type === 'FITNESS') {
      history.push(`/education/videos-library/${details['id']}`)
    } else if (type === 'ARTICLE') {
      history.push(`/education/adventure/${details['category_name'].toLowerCase()}/article/${details['id']}`)
    }
  };

  render() {
    const { data, t } = this.props;
    const { onHover } = this.state;
    return (
      <MainContainerV2 >
        <div>
          <WeekdayText color={"#69C2FF"}>
            {t("Movement Monday!")}
          </WeekdayText>
          <CardNew recommended={1} background={"#69C2FF"}>
            <div onMouseEnter={() => this.onEnterMouse(data[2]&& data[2]['details'] && data[2]['details']['id'] && data[2]['details']['id'])} onMouseLeave={() => this.onLeaveMouse()} className='image-card'>
              <Thumbnail
                src={`${ImageUrl}/${data[2]['thumbnail_image']}`}
                alt={"recommended card"}
                height={"240px"}
              />
              {data[2]['details'] && onHover === data[2]['details']['id'] &&
                <div className='educateImageBackground' onClick={() => this.cardClick(data[2].type, data[2].details)}>
                  <div className="getStarted" >{t("Get Moving")}</div>
                </div>
              }
            </div>
            <BottomCard>{t(data[2].title)}</BottomCard>
          </CardNew>
        </div>
        <div>
          <WeekdayText color={"#F6B479"}>
            {t("Wellness Wednesday!")}
          </WeekdayText>
          <CardNew recommended={1} background={"#F6B479"}>
            <div onMouseEnter={() => this.onEnterMouse(data[0]&& data[0]['details'] && data[0]['details']['id'] && data[0]['details']['id'])} onMouseLeave={() => this.onLeaveMouse()} className='image-card'>
              <Thumbnail
                src={`${ImageUrl}/${data[0]['thumbnail_image']}`}
                alt={"recommended card"}
                height={"240px"}
              />
              {data[0]['details'] && onHover === data[0]['details']['id'] && 
                <div className='educateImageBackground' onClick={() => this.cardClick(data[0].type, data[0].details)}>
                  <div className="getStarted" >{t("Start Learning")}</div>
                </div>
              }
            </div>
            <BottomCard>{t(data[0].title)}</BottomCard>
          </CardNew>
        </div>
        <div>
          <WeekdayText color={"#FD7175"}>
            {t("Foodie Friday!")}
          </WeekdayText>
          <CardNew recommended={1} background={"#FD7175"}>
            <div onMouseEnter={() => this.onEnterMouse(data[1]&& data[1]['details'] && data[1]['details']['id'] &&data[1]['details']['id'])} onMouseLeave={() => this.onLeaveMouse()} className='image-card'>
              <Thumbnail
                src={`${ImageUrl}/${data[1]['thumbnail_image']}`}
                alt={"recommended card"}
                height={"240px"}
              />
              {data[1]['details'] && onHover === data[1]['details']['id'] &&
                <div className='educateImageBackground' onClick={() => this.cardClick(data[1].type, data[1].details)}>
                  <div className="getStarted" >{t("Start Cooking")}</div>
                </div>
              }
            </div>
            <BottomCard>{t(data[1].title)}</BottomCard>
          </CardNew>
        </div>
      </MainContainerV2>
    );
  }
}

Cards.propTypes = {
  history: PropTypes.object,
  data: PropTypes.array,
  t: PropTypes.func
};

export default (withTranslation()(Cards));